import React from 'react'

/* 
  Use with new Gatsby Head api, works only on page components
  Will be added to static html at build time - important for open graph meta tags
  Import this component and export a Head function on each page
  Use title prop to override on each page
  
    export const Head = () => (
      <Seo title="page title" />
    )
*/

const Seo = (props) => {
  const defaultTitle = 'Healthcare Intelligence Engine | PINC AI'
  const defaultOGTitle = 'PINC AI';
  const defaultOGDescription = 'Powering The Future Of Healthcare With PINC AI™ Data, Technology And Services';
  const defaultOGImage = 'https://www.pinc-ai.com/metadata/generic_post_graphic_pincai.jpg'
  
  // Add other props here to override per page
  const { title = defaultTitle, seo, children } = props
  return (
    <>
      <title>{title}</title>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=5"
        />
      <meta charSet="utf-8" />
      <meta name="referrer" content="origin" />

      <meta property="og:site_name" content="PINC AI"></meta>

      <meta property="og:title" content={seo && seo.title ? seo.title : defaultOGTitle} />
      <meta property="og:description" content={seo && seo.description ? seo.description : defaultOGDescription} />
      <meta property="og:image" content={seo && seo.image?.asset?.url ? seo.image.asset.url : defaultOGImage} />
      <meta property="og:image:secure_url" content={seo && seo.image?.asset?.url ? seo.image.asset.url : defaultOGImage} />

      <meta name="twitter:card" content="summary" />
      {/* <meta name="twitter:site" content="@PINC_AI" /> */}

      {/* tag from JB Mar 3 2023 */}
      <meta name="google-site-verification" content="e25XiwGMTeAxoJGCOGSeXCk1V0y7R1aY9h7yiUZepZ4" />
      {children}
    </>
  )
}

export default Seo
